import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { trim } from 'lodash'

import ContactModal from './ContactModal/ContactModal'
import alp_logo from '../../assets/images/brands/alp.webp'
import getMdor from '../../content/getMdor'
import ReCAPTCHA from 'react-google-recaptcha'
import PrivacyAndPolicy from '../Inquire/PrivacyAndPrivacy/PrivacyAndPolicy'

import './DeedOfRestriction.scss'

type Inputs = {
  company_code: string
  contract_number: string
  agreement: boolean
  captchaToken: any
}

let captcha: any

const MDORComponent = () => {
  const GOOGLE_RECAPTCHA_SITE_KEY = '6LcP3okaAAAAAI0EsBqLfN57Fio2dRFFRJkwUQTy'
  const { register, handleSubmit, trigger, errors, setValue, reset } = useForm<Inputs>({
    mode: 'onChange'
  })
  const [contactModal, toggleContactModal] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [privacyPolicy, togglePrivacyPolicy] = useState<boolean>(false)

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true)
    setError('')
    console.log(data)

    const { file, token, error: requestError } = await getMdor({
      companyCode: data.company_code,
      contractNo: data.contract_number,
      brand: 'ALP',
      recaptchaToken: data.captchaToken
    })
    console.log(file ? file : '')
    if (file) {
      navigate('/deed-of-restriction/view', {
        state: { pdf: file, token }
      })
      setIsLoading(false)
    } else {
      setError(requestError || 'The details you have entered are incorrect. Please double-check and try again.')
      setIsLoading(false)
      captcha.reset()

      setValue('captchaToken', '')
      trigger('captchaToken')
    }
  }

  const verifyRecaptcha = (token: any) => {
    setValue('captchaToken', token)

    if (token) {
      trigger('captchaToken')
    }
  }

  useEffect(() => {
    register(
      {
        name: 'captchaToken'
      },
      {
        required: 'Please verify that you are not a robot.'
      }
    )

    typeof window !== 'undefined'
      ? (window.onpopstate = function () {
          window.location.reload()
        })
      : null
  })

  return (
    <>
      <div className="mdor">
        <div className="mdor-logo-container">
          <Link to="/" className="logo-link">
            <img loading="lazy" src={alp_logo} alt="ALP logo" className="mdor-brand-logo" />
          </Link>
        </div>

        <div className="mdor-content-wrapper">
          <div className="mdor-header">
            <p className="mdor-title mdor-title-lg">MASTER DEED WITH DECLARATION OF RESTRICTIONS</p>
            <p className="mdor-title">
              MASTER DEED <br /> WITH DECLARATION OF RESTRICTIONS
            </p>
            <p className="mdor-subtitle">
              Thank you for your purchase. To access your copy, please input your contact number and corresponding company code in the
              fields below.*
            </p>
            <p className="mdor-note">*These can be found in the Annex A of the Contract to Sell (CTS)</p>
          </div>

          <div className="form-wrapper">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} method="post">
              <div className="form-group">
                <div className="form-control">
                  <input
                    onChange={() => {
                      trigger('company_code')
                    }}
                    className="mdor-input"
                    name="company_code"
                    type="text"
                    placeholder="Company Code"
                    ref={register({
                      required: 'Company Code required',
                      validate: (value) => !!trim(value) || 'Invalid company code'
                    })}
                  />
                  {errors.company_code && <small className="mdor-error">{errors.company_code.message}</small>}
                </div>

                <div className="form-control">
                  <input
                    onChange={() => {
                      trigger('contract_number')
                    }}
                    className="mdor-input"
                    name="contract_number"
                    type="text"
                    placeholder="Contract Number"
                    ref={register({
                      required: 'Contract number required',
                      validate: (value) => !!trim(value) || 'Invalid contract number'
                    })}
                  />
                  {errors.contract_number && <small className="mdor-error">{errors.contract_number.message}</small>}
                </div>

                <div className="form-control">
                  <label className="mdor-checkbox" htmlFor="agreeCheckbox">
                    <span className="checkbox-label">
                      This website makes use of cookies to improve your browsing experience. By clicking this button, you accept the use of
                      cookies on this website. Learn more about our{' '}
                      <a
                        className="privacy-link"
                        onClick={(e) => {
                          e.preventDefault()
                          togglePrivacyPolicy(!privacyPolicy)
                        }}
                      >
                        Privacy Notice here.
                      </a>
                    </span>
                    <input
                      onChange={() => {
                        trigger('agreement')
                      }}
                      type="checkbox"
                      name="agreement"
                      id="agreeCheckbox"
                      ref={register({
                        required: 'You must agree to our Privacy Notice.'
                      })}
                    />
                    <div className="checkbox-indicator"></div>
                  </label>
                  {errors.agreement && <small className="mdor-error">{errors.agreement.message}</small>}
                </div>

                <div>
                  <div className="mdor-captcha">
                    <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITE_KEY} ref={(e) => (captcha = e)} onChange={verifyRecaptcha} />
                  </div>
                  {errors.captchaToken && <small className="mdor-error">{errors.captchaToken.message}</small>}
                </div>
              </div>

              <h5 className="mdor-error" style={{ marginTop: '15px' }}>
                {error}
              </h5>
              {isLoading && <h5 className="mdor-error">Please wait...</h5>}
              <button className="mdor-btn">Submit</button>
            </form>
          </div>

          <button
            className={`mdor-btn mdor-contact-btn ${contactModal ? 'mdor-contact-btn--fixed' : ''}`}
            onClick={() => toggleContactModal(true)}
          >
            Trouble logging in?
          </button>
        </div>
      </div>

      {contactModal && (
        <ContactModal
          {...{
            showModal: contactModal,
            toggleModal: toggleContactModal
          }}
        />
      )}
      {privacyPolicy && (
        <PrivacyAndPolicy
          {...{
            showModal: privacyPolicy,
            toggleModal: togglePrivacyPolicy,
            currentPath: '/deed-of-restriction'
          }}
        />
      )}
    </>
  )
}

export default MDORComponent
