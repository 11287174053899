import React, { ReactElement } from 'react'
import MDORComponent from '../../components/DeedOfRestriction/DeedOfRestriction'
import './DeedOfRestriction.scss'

const MDORTemp = (): ReactElement => {
  return (
    <div className="mdor-content">
      <MDORComponent />
    </div>
  )
}

export default MDORTemp
