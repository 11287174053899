type MdorTypes = {
  id?: string
  file?: string
  project?: string
  error?: string
  token?: string
}

type GetMdorPropTypes = {
  companyCode: string
  contractNo: string
  brand: string
  recaptchaToken: any
}

const getMdor = async ({ companyCode, contractNo, brand, recaptchaToken }: GetMdorPropTypes): Promise<MdorTypes> => {
  let apiError = ''
  const query = `mutation MdorEmployeeLogin(
            $employeeMdorLoginInput: EmployeeMdorLoginInput!
            ) {
            mdorEmployeeLogin(
                employeeMdorLoginInput: $employeeMdorLoginInput
            ) {
                token
                document {
                  mdor_document {
                    document_custom_id
                    file
                    __typename
                  }
                }
              }
           }`

  try {
    const response = await fetch(process.env.GATSBY_API_URL || '', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          employeeMdorLoginInput: {
            companyCode,
            contractNo,
            brand,
            recaptchaToken
          }
        }
      })
    })

    const jsonResponse = await response.json()
    const {
      token,
      document: { mdor_document: { id = '', file = '', project = '' } } 
    } = jsonResponse.data.mdorEmployeeLogin

    return {
      token,
      id,
      file,
      project
    }
  } catch (e) {
    return {
      error: 'The details you have entered are incorrect. Please double-check and try again.'
    }
  }
}

export default getMdor
