import React from 'react'
import CustomModal from '../../Modal/CustomModal'
import './ContactModal.scss'

const ContactModal = ({ showModal, toggleModal }: any) => {
  return (
    <CustomModal
     {...{ 
       showModal, toggleModal, 
       width: '100%', 
       maxWidth: '584px', 
       height: 'auto' 
      }}
    >
      <div className="contact-modal">
        <p className="contact-modal-header">
          Trouble logging in?
        </p>
        <p className="contact-modal-content">
          You may get in touch with the <span className="contact-modal-residential">Residential Customer Center</span> 
          through{' '} <span className="contact-modal-number">8848-5000</span>.
        </p>
      </div>
    </CustomModal>
  )
}

export default ContactModal
